import React from "react"
import Layout from "../components/layout"
import ColorDots from "../components/ColorDots/ColorDots"
import EmailSignup from "../components/EmailSignup/EmailSignup"
import Footer from "../components/Footer/Footer"
import Container from "../components/Container/Container"

const HeaderTwo = ({ children }) => (
  <h2 style={{ fontSize: "1.2em", marginBottom: "20px" }}>{children}</h2>
)

const PrivacyPolicy = () => {
  return (
    <Layout>
      <div>
        <Container thin>
          <h1 className="responsiveAlign" style={{ margin: "60px 0 40px 0" }}>
            Privacy Policy
          </h1>
          <p>
            Your privacy is very important to us. We want to make your
            experience on the internet as enjoyable as possible. We have created
            this Medical Marijuana Privacy Policy to demonstrate our firm
            commitment to privacy and security. This Privacy Policy describes
            how KindBio collects information from all end users of our internet
            (referred to as “Visitors” below).
            <br />
            <br />
            This Privacy Policy also describes what we do with the information
            we collect and the choices Visitors have concerning the collection
            and use of such information. We request that you read this Privacy
            Policy carefully.
            <br />
            <br />
          </p>
          <HeaderTwo>
            Personal Data KindBio Collects and How it is Used
          </HeaderTwo>
          <p>
            KindBio collects personal data in different ways from Visitors who
            access our network. We use this information primarily to provide a
            customized experience as Visitors use our internet features.
            <br />
            <br />
            KindBio may process the following categories of personal data about
            you:
            <br />
            <br />
            Communication Data that includes any communication that you send to
            us whether that be through the contact form on our website, through
            email, text, social media messaging, social media posting or any
            other communication that you send us. We process this data for the
            purposes of communicating with you and for record keeping.
            <br />
            <br />
            User Data that includes data about how you use our website and any
            online features together with any data that you post for publication
            on our website or through other online features. We process this
            data to operate our website and ensure relevant content is provided
            to you, to ensure the security of our website, to maintain backups
            of our website and/or databases and to enable publication and
            administration of our website.
            <br />
            <br />
            Technical Data that includes data about your use of our website and
            online features such as your IP address, your login data, details
            about your browser, length of visit to pages on our website, page
            views, and navigation paths, details about the number of times you
            use our website, time zone settings and other technology on the
            devices you use to access our website. The source of this data is
            from our analytics tracking system. We process this data to analyze
            your use of our website, to administer and protect our business and
            website, to deliver relevant content and advertisements to you, and
            to understand the effectiveness of our advertising.
            <br />
            <br />
            Marketing Data that includes data about your preferences in
            receiving marketing from us and your communication preferences. We
            process this data to enable you to partake in our promotions, to
            deliver relevant website content and advertisements to you, and
            measure or understand the effectiveness of this advertising.
            <br />
            <br />
            We may use User Data, Technical Data and Marketing Data to deliver
            relevant website content and advertisements to you and to measure or
            understand the effectiveness of the advertising we serve you.
            <br />
            <br />
          </p>
          <HeaderTwo>Responses to Email Inquiries</HeaderTwo>
          <p>
            When visitors send email inquiries to KindBio, the return email
            address is used to answer the email inquiry we receive. KindBio does
            not use the return email address for any other purpose and does not
            share the return email address with any third party.
            <br />
            <br />
          </p>
          <HeaderTwo>Voluntary Patient Surveys</HeaderTwo>{" "}
          <p>
            We may periodically conduct both business and individual patient
            surveys. We encourage our patients to participate in these surveys
            because they provide us with important information that helps us to
            improve the types of products we offer and how we provide them to
            you. Your personal information and responses will remain strictly
            confidential, even if the survey is conducted by a third party.
            Participation in our customer surveys is voluntary.
            <br />
            <br />
            We may take the information we receive from individuals responding
            to our Patient surveys and combine (or aggregate) it with the
            responses of other patients we may have to create broader, generic
            responses to the survey questions (such as gender, age, residence,
            hobbies, education, employment, industry sector, or other
            demographic information). We then use the aggregated information to
            improve the quality of our products to you and to develop new ones.
            <br />
            <br />
          </p>
          <HeaderTwo>Cookies and How KindBio Uses Them</HeaderTwo>
          <p>
            A “cookie” is a small data file that can be placed on your hard
            drive when you visit certain web sites. KindBio may use cookies to
            collect, store and sometimes track information for statistical
            purposes to improve the products we provide.
            <br />
            <br />
            If you are a Visitor, we may use a cookie to save your settings and
            to provide customizable and personalized services. These cookies do
            not enable third parties to access any of your patient information.
            Additionally, be aware that if you visit other web sites where you
            are prompted to log in or that are customizable, you may be required
            to accept cookies.
            <br />
            <br />
            Partners may also use their own cookies. We not control use of these
            cookies and expressly disclaim responsibility through them.
            <br />
            <br />
            KindBio Commitment to Children’s Privacy: Protecting children’s
            privacy is especially important to us. It is our policy to comply
            with the Children’s Online Privacy Protection Act of 1998 and all
            other applicable laws. Therefore, we restrict our web site to
            persons twenty one years or older.
            <br />
            <br />
            YOU MUST BE TWENTY ONE (21) YEARS OR OLDER TO ACCESS THIS WEB SITE.
            IF YOU ARE UNDER 21 YEARS OF AGE, YOU ARE NOT PERMITTED TO ACCESS
            THIS WEB SITE FOR ANY REASON DUE TO THE AGE RESTRICTIONS FOR USE OF
            THIS WEB SITE. NO INFORMATION OBTAINED BY THIS WEB SITE FALLS WITHIN
            THE CHILD ONLINE PRIVACY ACT (COPA) AND IS NOT MONITORED AS DOING
            SO.
          </p>
          <HeaderTwo>Public Forums</HeaderTwo>
          <p>
            Please remember that any information you may disclose in any Visitor
            directory or other public areas of our web sites or the internet’s
            becomes public information. You should exercise caution when
            deciding to disclose personal information in these public areas.
            <br />
            <br />
          </p>
          <HeaderTwo>KindBio’s Commitment to Data Security</HeaderTwo>
          <p>
            Services and web sites that we sponsor have security measures in
            place to protect the loss, misuse, and alteration of the information
            under our control. While we make every effort to ensure the
            integrity and security of our network and systems, we cannot
            guarantee that our security measures will prevent third party
            “hackers” from illegally obtaining this information.
            <br />
            <br />
          </p>
          <HeaderTwo>Changes to Personal Data</HeaderTwo>
          <p>
            Visitors to KindBio are obliged to inform KindBio about any change
            concerning. his/her data, in particular about changes of place of
            residence, changes of surname, and changes of email address. KindBio
            shall not be liable for the consequences resulting from providing
            mistaken data.
            <br />
            <br />
          </p>
          <HeaderTwo>
            Where to Direct Questions About Our Privacy Policy
          </HeaderTwo>
          <p>
            If you have any questions about this Privacy Policy or the practices
            described herein, you may contact us via our web site.
            <br />
            <br />
          </p>
          <HeaderTwo>Revisions to This Policy</HeaderTwo>
          <p>
            KindBio reserves the right to revise, amend, or modify this policy,
            our Terms of Use Agreement, our Policies and Procedures, and our
            other policies and agreements at any time and in any manner by
            updating this posting.
          </p>
        </Container>
        <EmailSignup></EmailSignup>
        <Footer></Footer>
      </div>
    </Layout>
  )
}

export default PrivacyPolicy
